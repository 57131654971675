;(function(ScreenSize, Expandable) {
    //INGRWD-222
    function makeExpandable(elements, item, options) {
        var item = $(item);
        options = $.extend({}, {defaultExpandText: "Rozwiń", defaultCollapseText: "Zwiń"}, options);
        options = $.extend({}, {expandText: options.defaultExpandText, collapseText: options.defaultCollapseText}, {expandText: item.data("expand"), collapseText: item.data("collapse")}, options);

        var expandable = $("<div>");
        var listWrapper = $("<div>").addClass("list").appendTo(expandable);
        var list = $("<div>").appendTo(listWrapper);

        expandable.append('<p class="more initialHide"><span class="expand"><span>' + options.expandText + '</span></span><span class="collapse" style="display: none;"><span>' + options.collapseText + '</span></span></p>');
        elements.first().before(expandable);
        list.append(elements);
        return expandable[0];
    }
    function makeCommonTableExpandable(item) {
        var item = $(item);
        var table = item.closest("table");
        var index = table.find("> THEAD > TR, > TBODY > TR").index(item);

        var elements = table;
        var expandable = makeExpandable(elements, item);

        var listSelector = "> .list > DIV";
        var itemsSelector = "> .list > DIV > TABLE > THEAD > TR, > .list > DIV > TABLE > TBODY > TR";

        var expandableObj = new Expandable(expandable, {index_of_last_visible_block: index+1, listSelector: listSelector, itemsSelector: itemsSelector});
        return expandableObj;
    }

    $("#outlet-content").on('content-replace', function() {

        $("TR.last_visible").each(function() {
            var item = $(this);
            var table = item.closest("table");
            if (table.hasClass("table_type2") && deviceUtils.isDesktop){
                makeCommonTableExpandable(item);
            }
        });

        $("P.last_visible").each(function() {
            var item = $(this);
            var index = item.index();

            var elements = item.parent().children().filter(":not(DIV.clearFix)");
            var expandable = makeExpandable(elements, item);

            var listSelector = "> .list > DIV";
            var itemsSelector = "> .list > DIV > *";
            new Expandable(expandable, {index_of_last_visible_block: index+1, listSelector: listSelector, itemsSelector: itemsSelector});
        });

        $("P.expand_element").each(function() {//, P.xs_expand_element
            var item = $(this);

            item = item.before($("<p>").addClass("expandable_flat_item")).prev().add(item);

            var expandable = makeExpandable(item, this, {defaultExpandText: "Więcej szczegółów"});
            $(expandable).addClass("inner");

            var onCondition;
            if (item.hasClass("xs_expand_element")) {
                onConditionx = function() {
                    // o tym powinien decydować typ tabeli np. table_type2 ma drugą klasę
                    return ScreenSize.is(ScreenSize.SIZES.XSMALL);
                }
            }
            var listSelector = "> .list > DIV";
            var itemsSelector = "> .list > DIV > *";
            new Expandable(expandable, {index_of_last_visible_block: 1, listSelector: listSelector, itemsSelector: itemsSelector, onCondition: onCondition});
        });

    }); // ready
})(rwd.ScreenSize, rwd.Expandable);
